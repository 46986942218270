.code {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.box {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.continue-btn {
    padding-bottom: 5px;
}

button.btn-link {
    font-weight: 500;
    color: #646cff;
    text-decoration: none;
}