/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.list-item {
    display: flex;
    justify-content: space-between;
}

.new-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.new-item {
    margin-top: 25px;
}

.new-item .btn {
    margin-left: 10px;
}

.top-area {
    text-align: right;
    margin-top: 5px;
    margin-bottom: 10px;
}

.top-area .icon {
    margin-right: 10px;
    color: grey;
    font-size: 20px;
    cursor: pointer;
}

.top-area .btn {
    margin-right: 10px;
}

.trash {
    color: darkred;
    cursor: pointer;
}

.complete {
    color: grey;
}

.code-display {
    color: grey;
}

.bottom-actions {
    margin-top: 10px;
}

.bottom-actions .btn {
    margin-left: 15px;
}
